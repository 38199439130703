import axios from "axios";

export default {
  methods: {

    get_date_fe() {
      return axios
        .get('/json/date.conf')
        .then(
          (response) => {
            return response.data;
          },
          (error) => {
            return error.response;
          }
        );
    },

    get_conf(date_fe) {
      return axios
        .get('/json/'+date_fe+'.json')
        .then(
          (response) => {
            return response.data;
          },
          (error) => {
            return error.response;
          }
        );
    },

  },
};
